.image-component {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffb547b4;
  padding: 30px;
}

.main-section {
  display: flex;
  width: 80%;
  position: relative;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.box.slide-in {
  opacity: 1;
  transform: translateX(0%);
}

.box.slide-out {
  position: absolute;
  opacity: 0;
  transform: translateX(-100%);
}

.txt {
  margin: 20px;
  width: 100%;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.txt h1 {
  font-size: 45px;
  color: #000000;
}

.txt h5 {
  font-size: larger;
  color: #303030;
}

.txt p {
  font-size: large;
  color: #141414;
}

.main-image-gallery {
  width: 60%;
  height: auto;
  transition: opacity 0.3s ease-in-out;
  border-radius: 20px;
}

.main-image-gallery.fade {
  opacity: 0.5;
}

.thumbnails-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.prev-button,
.next-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  color: #000000;
}

.next-button {
  transform: rotate(180deg);
}

.thumbnails-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
  border-radius: 10px;
}

.thumbnail.selected {
  border: 1px solid #000000;
}

@media (max-width: 1280px) {
  .image-component {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .main-section {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 90%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .box.slide-out {
    position: relative;
  }

  .txt {
    margin: 10px;
    width: 100%;
    border-radius: 10px;
    margin-top: 35px;
    padding: 10px;
    transition: opacity 0.5s ease-in-out;
    color: white;
  }

  .txt h1 {
    font-size: 20px;
  }

  .txt h5 {
    font-size: small;
  }

  .main-image-gallery {
    width: 90%;
    height: auto;
    border-radius: 10px;
  }

  .thumbnails-section {
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .prev-button {
    font-size: 18px;
    padding: 5px;
    transform: rotate(-90deg);
  }
  .next-button {
    font-size: 18px;
    padding: 5px;
    transform: rotate(90deg);
  }

  .thumbnails-container {
    flex-direction: row;
    gap: 5px;
  }

  .thumbnail {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 480px) {
  .image-component {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .main-section {
    width: 100%;
    height: 100%;
    margin-bottom: 5px;
    flex-direction: column;
    align-items: center;
  }

  .box {
    width: 90%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .box.slide-out {
    position: relative;
  }

  .txt {
    margin: 5px;
    width: 100%;
    border-radius: 5px;
    margin-top: 25px;
    padding: 5px;
    transition: opacity 0.5s ease-in-out;
    color: white;
  }

  .txt h1 {
    font-size: 20px;
  }

  .txt h5 {
    font-size: small;
  }

  .main-image-gallery {
    width: 90%;
    height: auto;
    border-radius: 5px;
  }

  .thumbnails-section {
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .prev-button {
    font-size: 14px;
    padding: 3px;
    transform: rotate(-90deg);
  }
  .next-button {
    font-size: 14px;
    padding: 3px;
    transform: rotate(90deg);
  }

  .thumbnails-container {
    flex-direction: row;
    gap: 3px;
  }

  .thumbnail {
    width: 40px;
    height: 40px;
  }
}
