.img-comp {
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  opacity: 0;
  transition: opacity 1s, transform 0.6s ease-in-out;
  background: #ffb547b4;
  transform: translatex(-40%);
}

.container.animate {
  opacity: 1;
  transform: translateY(0);
}

.container.reverse {
  transform: translatex(40%);
  flex-direction: row-reverse;
  background: #800000;
  opacity: 0;
}

.container.reverse.container.animate {
  opacity: 1;
  transform: translateY(0);
  position: relative;
}

.main-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.main-image {
  width: 80%;
  border-radius: 20px;
  margin: 10px;
  transition: opacity 0.5s ease-out, transform 0.6s ease-in-out;
  border: 4px solid white;
}

.main-image.fade {
  opacity: 0;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.text-container-inner {
  max-width: 500px;
  padding: 20px;
  text-align: center;
  background: #f0f0f0;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 1s ease-in-out;
  transform: translateX(50%);
}

.text-container-inner.slide-in-text {
  transform: translateX(0);
}

.text h1 {
  font-size: 40px;
  margin-bottom: 30px;
  color: #000000;
}

.text p {
  font-size: 22px;
  color: #777777;
  line-height: 1.6;
}

.side-images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.side-image {
  width: 80px;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  /* border: 1px solid #000000; */
}

.side-image:hover {
  transform: scale(1.1);
}

@media (max-width: 1280px) {
  .main-image {
    width: 70%;
  }

  .text h1 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .text p {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    padding: 20px;
    overflow-x: hidden;
  }

  .container.reverse {
    flex-direction: column;
  }

  .main-image-container {
    margin-bottom: 20px;
    flex-direction: column;
    width: 100%;
  }

  .text-container {
    width: 100%;
  }

  .side-images-container {
    flex-direction: row;
  }

  .side-image {
    width: 60px;
    height: 40px;
  }

  .text h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .text p {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    padding: 20px;
    overflow-x: hidden;
  }

  .container.reverse {
    flex-direction: column;
  }

  .main-image-container {
    margin-bottom: 20px;
    flex-direction: column;
    width: 100%;
  }

  .text-container {
    width: 100%;
  }

  .text .side-images-container {
    flex-direction: row;
    justify-content: center;
  }

  .side-image {
    width: 50px;
    height: 30px;
  }

  .text-container-inner {
    max-width: 100%;
    padding: 10px;
    transform: translateX(0);
  }

  .text h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .text p {
    font-size: 14px;
  }
}
