



.hero-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 2;
}

.home-container {
  position: relative;
  z-index: 1;
}

.instagram-feed {
  border: 2px solid #ffb3ba;
  color: black;
  padding: 50px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.hero-content {
  position: absolute;
  width: 100%;
  height: 70%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.hero-content.in-view {
  opacity: 1;
  transform: translateY(0%);
}

.hero-content h1 {
  font-size: 80px;
  margin-bottom: 50px;
  color: #ffffff;
}

.hero-content h1.in-view {
  opacity: 1;
  transform: translateY(0%);
}

.hero-content p {
  font-size: 30px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #ffffff;
}

.hero-content p.in-view {
  opacity: 1;
  transform: translateX(0%);
}

.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #6a0d00;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 40px;
  font-weight: bold;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.cta-button-fixed {
  position: fixed;
  bottom: 80px;
  right: 30px;
  z-index: 1000;
  padding: 15px 30px;
  background-color: #6a0d00;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.cta-button:hover,
.cta-button-fixed:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

/* .separator-bar {
  width: 100%;
  height: 150px;
  background-color: #4a3624;
  border: none;
  margin: 0;
} */

.content-box {
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #ffdcb1;
}

.social-icons-home {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
  padding: 25px;
}

.social-icons-home a {
  color: #ffffff;
  font-size: 30px;
}

.business-section {
  padding: 50px;
  text-align: center;
}

.business-content {
  position: relative;
  max-width: 600px;
  min-height: 100px;
  margin: 0 auto;
  padding: 40px;
  transition: max-width 0.8s ease, padding 1.5s ease;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.business-content img {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: -1;
}

.business-content.expanded img {
  width: 50%;
}

.business-content.expanded {
  max-width: 100%;
  min-height: 200px;
}

.business-content h2 {
  cursor: pointer;
  text-align: center;
  color: #e6d3bb;
  font-size: 40px;
}

.business-content p {
  margin-top: 20px;
  font-size: 22px;
  font-weight: normal;
  color: white;
}

.contattaci-business-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ffbc0d;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 40px;
  font-weight: bold;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

@media (max-width: 768px) {
  .hero-content {
    height: 60%;
  }

  .hero-content h1 {
    font-size: 70px;
    margin-bottom: 30px;
  }

  .hero-content p {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 60px;
  }

  .cta-button,
  .cta-button-fixed {
    font-size: 20px;
    padding: 12px 24px;
    margin-top: 20px;
  }

  .cta-button-fixed {
    bottom: 80px;
    right: 30px;
  }

  .business-section {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .home-container {
    width: 100%;
  }

  .hero-section {
    width: 100%;
  }

  .hero-content {
    top: 10%;
    height: 50%;
  }

  .hero-content h1 {
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .hero-content p {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 2;
    margin-top: 80px;
  }

  .cta-button,
  .cta-button-fixed {
    font-size: 18px;
    padding: 10px 20px;
    margin-top: 50px;
  }

  .cta-button-fixed {
    bottom: 60px;
    right: 20px;
  }

  .business-section {
    padding: 20px;
  }
}
