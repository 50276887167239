.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #6a0d00;
  padding: 12px 24px;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.navbar-brand img {
  height: 50px;
  margin-right: 10px;
}

.hamburger {
  display: none;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
  position: relative;
  margin: 0 20px;
}

.hamburger .line {
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: white;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger .line:nth-child(1) {
  top: 5px;
}

.hamburger .line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.hamburger .line:nth-child(3) {
  bottom: 5px;
}

.hamburger.is-active .line:nth-child(1) {
  transform: rotate(45deg);
  top: 13.5px;
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(3) {
  transform: rotate(-45deg);
  bottom: 13.5px;
}

.navbar-menu {
  display: flex;
  width: 100%;
  align-items: center;
  /* flex-grow: 1; */
  justify-content: center;
}

.navbar-links {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-left: auto;
  transition: opacity 0.3s;
}

.navbar-links li {
  position: relative;
}

.navbar-links a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
  position: relative;
  margin-bottom: -5px;
}

.navbar-links a:hover {
  color: #ffbc0d;
}

.navbar-links a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffbc0d;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.5s ease;
  margin-bottom: -5px;
}

.navbar-links a:hover::before {
  transform: scaleX(1);
}

.language-switch {
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.flag-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s;
}

.flag-icon.active {
  transform: scale(1.5);
}

.language-dropdown {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  transform-origin: top center;
  animation: dropdown-open 0.3s ease-out forwards;
}

.language-dropdown .flag-icon {
  width: 40px;
  height: 40px;
  margin: 5px 0;
  transition: transform 0.3s;
}

.language-dropdown .flag-icon:hover {
  transform: scale(1.2);
}

@keyframes dropdown-open {
  from {
    opacity: 0;
    transform: scaleY(0.9);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.content-padding {
  height: 56px;
}

@media (max-width: 768px) {
  .language-switch {
    margin: 0 20px;
  }

  .hamburger {
    display: block;
  }

  .navbar-menu {
    flex-direction: column;
    align-items: flex-start;
    display: none;
    width: 100%;
    padding-top: 8px;
    background-color: #4b0000;
    position: absolute;
    top: 75px;
    left: 0;
    z-index: 1001;
    transition: max-height 0.3s ease;
  }

  .navbar-menu.is-active {
    display: flex;
    max-height: fit-content;
  }

  .navbar-menu:not(.is-active) {
    max-height: 0;
    overflow: hidden;
  }

  .navbar-brand {
    width: 100%;
  }

  .navbar-links {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .navbar-links li {
    width: 100%;
    height: 45px;
    opacity: 0;
    animation: slide-down 0.3s ease-in-out;
    animation-fill-mode: both;
  }

  .navbar-links li:nth-child(1) {
    animation-delay: 0.1s;
  }
  .navbar-links li:nth-child(2) {
    animation-delay: 0.2s;
  }
  .navbar-links li:nth-child(3) {
    animation-delay: 0.3s;
  }
  .navbar-links li:nth-child(4) {
    animation-delay: 0.4s;
  }
  .navbar-links li:nth-child(5) {
    animation-delay: 0.5s;
  }

  .navbar-links a {
    font-size: 18px;
    padding: 10px;
    width: 100%;
    display: block;
    text-align: left;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.menu-page-container,
.menu-categories-sidebar,
.menu-container {
  z-index: 1;
  position: relative;
}

.menu {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}
